body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.pagination li .prev-next-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination li a,
.pagination li div,
.pagination li .page-link {
  background-color: #f1f5f9; /* slate-100 */
  color: #1e293b; /* slate-900 */
  font-size: 0.875rem; /* text-sm */
  font-weight: 400; /* font-normal */
  border-radius: 0.375rem; /* rounded */
  line-height: 1rem; /* leading-[16px] */
  display: flex;
  height: 1.5rem; /* h-6 */
  width: 1.5rem; /* w-6 */
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in-out; /* transition-all duration-150 */
}

.pagination li a.active,
.pagination li div.active,
.pagination li .page-link.active {
  background-color: #1e293b; /* slate-900 */
  color: #e5e7eb; /* slate-200 */
  font-weight: 500; /* font-medium */
}

.pagination.bordered {
  border: 1px solid #d1d5db; /* border-[#D8DEE6] */
  border-radius: 0.1875rem; /* rounded-[3px] */
  padding: 0.25rem 0.5rem; /* py-1 px-2 */
}

.pagination.bordered li {
  color: #6b7280; /* text-slate-500 */
}

.pagination.bordered li:first-child button,
.pagination.bordered li:last-child button {
  color: #6b7280; /* text-slate-500 */
  height: 1.5rem; /* h-6 */
  width: 1.5rem; /* w-6 */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem; /* rounded */
  transition: all 0.15s ease-in-out; /* transition duration-150 */
}

.pagination.bordered li:first-child button:hover,
.pagination.bordered li:last-child button:hover {
  background-color: #1e293b; /* hover:bg-slate-900 */
  color: #ffffff; /* hover:text-white */
}

.pagination.bordered li a,
.pagination.bordered li div,
.pagination.bordered li .page-link {
  background-color: transparent;
  color: #6b7280; /* text-slate-500 */
}

.pagination.bordered li a.active,
.pagination.bordered li div.active,
.pagination.bordered li .page-link.active {
  background-color: #1e293b; /* bg-slate-900 */
  color: #ffffff; /* text-white */
}

.pagination.border-group {
  border: 1px solid #d1d5db; /* border-[#D8DEE6] */
  border-radius: 0.1875rem; /* rounded-[3px] */
  padding: 0; /* px-0 */
  gap: 0; /* space-x-0 */
  direction: rtl;
}

.pagination.border-group li {
  border-right: 1px solid #d1d5db; /* border-r border-[#D8DEE5] */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.75rem; /* px-3 */
  color: #6b7280; /* text-slate-500 */
}

.pagination.border-group li:last-child {
  border-right: none; /* last:border-none */
}

.pagination.border-group li a,
.pagination.border-group li div,
.pagination.border-group li .page-link {
  background-color: transparent;
  color: #6b7280; /* text-slate-500 */
  height: auto; /* h-auto */
  width: auto; /* w-auto */
}

.pagination.border-group li a.active,
.pagination.border-group li div.active,
.pagination.border-group li .page-link.active {
  color: #1e293b; /* text-slate-900 */
  font-size: 1.125rem; /* text-lg */
}

.wave1 {
	display: inline-block;
	border: 15px solid transparent;
	border-top-color: currentColor;
	border-radius: 50%;
	border-style: solid;
	margin: 5px;
}
.wave2 {
	display: inline-block;
	border: 15px solid transparent;
	border-top-color: currentColor;
	border-radius: 50%;
	border-style: solid;
}